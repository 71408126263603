// components/ArticleList.tsx
import React, { } from 'react';
import { TFunction } from 'next-i18next';
import { textosPeque } from '@/constants/Dimens';
import { LogroObj } from '@/objects/Gamificacion';
import { cargarImagenLogro } from '@/util/Gamificacion';
import { black, lightGray } from '@/constants/Colors';
import Texto from '../elements/Texto';
import ImageCustom from '../elements/ImageCustom';

interface Props {
  t: TFunction;
  logro: LogroObj;
}

const LogroNotificacion: React.FC<Props> = ({ t, logro }) => {
  return (
    <div
      style={{
        cursor: "pointer", display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'space-between',
        width: "400px",
        color: black,
      }}>
      <article style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <section style={{ display: 'flex', flexDirection: 'column', width: "50px" }}>
          <ImageCustom alt={"logro_" + logro.nombre} src={cargarImagenLogro(logro)} width={50} height={50} />
        </section>
        <section style={{ display: 'flex', flexDirection: 'column', marginLeft: "20px", width: "230px" }}>
          <Texto >{logro.nombre}</Texto>
          <Texto  fontSize={textosPeque}>{logro.descripcion}</Texto>
        </section>
        <section style={{ display: 'flex', flexDirection: 'row', marginLeft: "20px", width: "90px", gap: "5px", marginRight: "5px" }}>
          <Texto >{logro.puntos}</Texto>
          <Texto >{t("common:articulos.creditos")}</Texto>
        </section>
      </article>
    </div>
  );
};

export default LogroNotificacion;
