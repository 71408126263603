

export interface GetLogrosObj {
    listaLogros: LogroObj[];
}

export interface LogroObj {
    id: IndiceLogro;
    nombre: string;
    descripcion: string;
    puntos: number;
    tipo: TipoLogro;
    completado?: boolean;
}

export interface GetLogroDesbloqueable {
    logro: LogroObj[];
}

export enum TipoLogro {
    BLOQUEADO = "bloqueado",
    BRONCE = "bronce",
    PLATA = "plata",
    ORO = "oro",
    FUEGO = "fuego",
}

export enum ImagenLogro {
    BLOQUEADO = "/images/trophy_empty.png",
    BRONCE = "/images/trophy_bronze.png",
    PLATA = "/images/trophy_silver.png",
    ORO = "/images/trophy_gold.png",
    FUEGO = "/images/trophy_fire.png",
}

export enum IndiceLogro {
    NUEVO_USER = 1,
    SUBIR_PRIMER_LIBRO = 2,
    SUBIR_5_LIBROS = 3,
    SUBIR_10_LIBROS = 4,
    CODIGO_BY_THE_FACE = 5,
    HISTORIA_QUIERO_LIBROS = 6,
    COMPARTIR_LIBRO = 7,
    DESCARGAR_APP = 8,
    PUNTUAR_APP = 9,
    PREMIUM = 10,
    VICULAR_APP_MIS_LEIDOS = 11,
    UTILIZA_APP_MIS_LEIDOS = 12,
    SINCRONIZAR_LIBROS_MIS_LEIDOS = 13
}