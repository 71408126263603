import sonido_compras from "../../public/sounds/cash.mp3";
import notificacion_logro from "../../public/sounds/notification_logro.mp3";

export const Sonidos = {
    COMPRAS: sonido_compras,
    LOGRO: notificacion_logro
}

export function cargarSonido(fuente: keyof typeof Sonidos) {
    //alert(fuente)
    const sonido = document.createElement("audio");
    sonido.src = Sonidos[fuente];
    sonido.setAttribute("preload", "auto");
    sonido.setAttribute("controls", "none");
    sonido.style.display = "none"; // <-- oculto
    document.body.appendChild(sonido);
    return sonido;
}

export function cargarSonidoSinObjeto(fuente: string) {
    const sonido = document.createElement("audio");
    sonido.src = fuente;
    sonido.setAttribute("preload", "auto");
    sonido.setAttribute("controls", "none");
    sonido.style.display = "none"; // <-- oculto
    document.body.appendChild(sonido);
    return sonido;
}