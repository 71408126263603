import RowLogro from "@/components/elements/listas/lista_logros/RowLogro";
import { mostrarSnackbar } from "@/context/UtilsContext";
import { GetLogroDesbloqueable, ImagenLogro, IndiceLogro, LogroObj, TipoLogro } from "@/objects/Gamificacion";
import { TFunction } from "next-i18next";
import { cargarSonido } from "./Sound";
import LogroNotificacion from "@/components/widgets/LogroNotificacion";
import GamificacionQueries from "@/network/Gamificacion";
import { cargarDatosUsuario } from "./LocalStorageLoaded";
import { AxiosResponse } from "axios";

export function cargarImagenLogro(logro: LogroObj): ImagenLogro {
    if (logro.tipo === TipoLogro.BLOQUEADO) {
        return ImagenLogro.BLOQUEADO;
    } else if (logro.tipo === TipoLogro.BRONCE) {
        return ImagenLogro.BRONCE;
    } else if (logro.tipo === TipoLogro.PLATA) {
        return ImagenLogro.PLATA;
    } else if (logro.tipo === TipoLogro.ORO) {
        return ImagenLogro.ORO;
    } else if (logro.tipo === TipoLogro.FUEGO) {
        return ImagenLogro.FUEGO;
    }
    return ImagenLogro.BLOQUEADO;
}

export function comprobarLogro(id_logro: IndiceLogro, gamificationQueries: GamificacionQueries, t: TFunction, sonido: boolean = true) {
    const datosUsuario = cargarDatosUsuario();
    gamificationQueries?.cargarLogroPorId(Number(datosUsuario?.id_user), id_logro).then((response: AxiosResponse<GetLogroDesbloqueable>) => {
        if (response.data.logro !== undefined) {
            mostrarLogro(response.data.logro[0], t, sonido);
        }
        //const logro: LogroObj = response.data.logro;
        //mostrarLogro(logro, t);
    }).catch((error: any) => {
        console.log("Error al comprobar Logro ",error);
    });

}

export function mostrarLogro(logro: LogroObj, t: TFunction, sonido: boolean = true) {
    if (sonido)
        cargarSonido("LOGRO").play();

    setTimeout(() => {
        mostrarSnackbar({
            content: <LogroNotificacion logro={logro} t={t} />
        });
    }, 20000); // 20000 milisegundos son 20 segundos
}
